import { inject, Injectable, signal } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { User } from './user.types';
import { LOCALSTORAGE_KEY } from '../../../shared/helpers/constants';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  readonly user = signal<User | undefined>(undefined);
  readonly userExists = signal<boolean>(false);
  private readonly baseUrl = environment.baseUrl;
  private readonly http = inject(HttpClient);


  constructor() {}

  async fetchUser() {
    const token = localStorage.getItem(LOCALSTORAGE_KEY);
    if (token) {
      let user;
      try {
        user = await lastValueFrom(
          this.http.get<User>(`${this.baseUrl}/auth/profile`, {
            headers: { Authorization: `Token ${token}` },
          })
        );
      } catch (error) {}
      return this.setUser(user);
    }
    return new Promise<{}>((resolve) => resolve({}));
  }

  setUser(user: User | undefined) {
    this.user.set(user);
    this.userExists.set(!!user);
  }

  getTenantId() {
    return this.user()?.tenants[0].tenantId || '';
  }
}
